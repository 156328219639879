import React from "react";
import { parse } from "querystring";
import { Timestamp } from "@google-cloud/firestore";
import { EventSaleStatus } from "../modules/event";
import label_onsale from "../designs/images/label_onsale.svg";
import label_end from "../designs/images/label_end.svg";
import label_soon from "../designs/images/label_soon.svg";
import { EVENT_OPEN_TIME_PERIOD } from "../constants/event";
import { selectImgPath, isOpenEventStreamingPage } from "./event";

import { i18nextT } from "../hooks/i18n/i18n";
import appConfig from "constants/appConfig";

import type { Cookie } from "@spwn/types/functions";
import type { Event, Place } from "@spwn/types/firebase/firestore";

/**
 * scroll
 */
export const scrollTop = () => {
  window.scrollTo(0, 0);
};

/*!
 * window
 */

/**
 * open new window
 * 相対パスの場合は `/` から始めること
 * @param url
 */
export const openWindow = (url: string) => {
  // @ts-expect-error TS2345
  window.open(url, null, "noopener");
};

/**
 * string utility
 */
// @ts-expect-error TS7006
export const toDoubleDigits = (num) => {
  return ("0" + num).slice(-2);
};
export const dayOfWeekStr = (dayIndex: number): string => {
  return (
    [
      i18nextT("common.string.dayOfWeek.Sun"),
      i18nextT("common.string.dayOfWeek.Mon"),
      i18nextT("common.string.dayOfWeek.Tue"),
      i18nextT("common.string.dayOfWeek.Wed"),
      i18nextT("common.string.dayOfWeek.Thu"),
      i18nextT("common.string.dayOfWeek.Fri"),
      i18nextT("common.string.dayOfWeek.Sat"),
    ][dayIndex] ?? ""
  );
};

// @ts-expect-error TS2322
export const getNowTimestamp = (date: Date = null) => {
  // firebaseのタイムスタンプに合わせた形で返す
  if (!date) {
    return Math.floor(Date.now() / 1000);
  } else {
    return Math.floor(date.getTime() / 1000);
  }
};
export const getCartExpiredMin = (expiredAt: Timestamp) => {
  // 残り分を返す
  const nowTimestamp = getNowTimestamp();
  const diffSeconds = Number(expiredAt.seconds - nowTimestamp);
  return Math.floor(diffSeconds / 60);
};

// 改行タグ変換
// @ts-expect-error TS7006
export const replaceBr = (word) => {
  return !word ? "" : word.replace(/<(\/|)br(\/|)>/g, "\n");
};
// @ts-expect-error TS7006
export const removeBr = (text) => {
  return !text ? "" : text.replace(/<(\/|)br(\/|)>/g, "");
};
// 改行タグsplit
export const splitBr = (word: string) => {
  if (!word) {
    return [];
  }
  return word.split("<br/>");
};

export const getItemTotal = (list: unknown[], item: string): number => {
  if (list.length === 0) {
    return 0;
  }
  return (
    list
      // @ts-expect-error TS18046
      .map((el) => el[item] || 0)
      .reduce((prev, cur) => Number(prev) + Number(cur))
  );
};

// @ts-expect-error TS7006
export const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

// @ts-expect-error TS7006
function strLength(strSrc) {
  let len = 0;
  strSrc = escape(strSrc);
  for (let i = 0; i < strSrc.length; i++, len++) {
    if (strSrc.charAt(i) === "%") {
      if (strSrc.charAt(++i) === "u") {
        i += 3;
        len++;
      }
      i++;
    }
  }
  return len;
}

// @ts-expect-error TS7006
export const multiByteStringSlice = (str, strLimit) => {
  let isSlice = false;

  while (strLength(str) > strLimit) {
    str = str.slice(0, str.length - 1);
    isSlice = true;
  }

  if (isSlice) {
    str += "…";
  }
  return str;
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// @ts-expect-error TS7006
export const getCommaStyleNumber = (value) => {
  return String(value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
};

export const getDateString = (date: Date) => {
  return (
    String(date.getFullYear()) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + date.getDate()).slice(-2)
  );
};

export const getDateTimeString = (date: Date) => {
  if (!date) {
    return "";
  }
  return (
    String(date.getFullYear()) +
    i18nextT("common.string.dateTime.year") +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    i18nextT("common.string.dateTime.month") +
    ("0" + date.getDate()).slice(-2) +
    i18nextT("common.string.dateTime.day") +
    toDoubleDigits(date.getHours()) +
    ":" +
    toDoubleDigits(date.getMinutes())
  );
};

export const getSlashSeparatedDateTimeString = (date: Date) => {
  if (!date) {
    return "";
  }
  return (
    String(date.getFullYear()) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + date.getDate()).slice(-2) +
    " " +
    toDoubleDigits(date.getHours()) +
    ":" +
    toDoubleDigits(date.getMinutes())
  );
};

export const getSlashSeparatedDateString = (date: Date) => {
  if (!date) {
    return "";
  }
  return (
    String(date.getFullYear()) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + date.getDate()).slice(-2) +
    " " +
    "(" +
    dayOfWeekStr(date.getDay()) +
    ")"
  );
};

export const getDateDayTimeString = (date: Date) => {
  return (
    String(date.getFullYear()) +
    i18nextT("common.string.dateTime.year") +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    i18nextT("common.string.dateTime.month") +
    ("0" + date.getDate()).slice(-2) +
    i18nextT("common.string.dateTime.day") +
    "(" +
    dayOfWeekStr(date.getDay()) +
    ")" +
    toDoubleDigits(date.getHours()) +
    ":" +
    toDoubleDigits(date.getMinutes())
  );
};

export const getDotSeparatedDayTimeString = (date: Date) => {
  return (
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "." +
    ("0" + date.getDate()).slice(-2) +
    "(" +
    dayOfWeekStr(date.getDay()) +
    ") " +
    toDoubleDigits(date.getHours()) +
    ":" +
    toDoubleDigits(date.getMinutes())
  );
};

// @ts-expect-error TS7006
export const getSlashSeparatedDateStyle = (ms_timestamp) => {
  const date = new Date(Number(ms_timestamp));
  const year = String(date.getFullYear()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return `${year}/${month}/${day}`;
};

const getDateFromFbTimestamp = (fbTimestamp: Timestamp) => {
  return new Date(fbTimestamp.seconds * 1000);
};

export const getSlashSeparatedDateTimeStringFromFbTimestamp = (
  fbTimestamp: Timestamp
) => {
  const date = getDateFromFbTimestamp(fbTimestamp);
  return getSlashSeparatedDateTimeString(date);
};

/**
 * convert datetime to hh:ii / e.g. 12:30
 * @param datetime
 */
export const convertDatetimeToHHII = (datetime: Date | Timestamp) => {
  if (datetime instanceof Date) {
    return `${toDoubleDigits(datetime.getHours())}:${toDoubleDigits(
      datetime.getMinutes()
    )}`;
  } else {
    const fsDatetime = datetime.toDate();
    return `${toDoubleDigits(fsDatetime.getHours())}:${toDoubleDigits(
      fsDatetime.getMinutes()
    )}`;
  }
};

export const getAbbreviatedText = (text: string, length: number) => {
  return text.length > length ? text.slice(0, length) + "..." : text;
};

// 配列の重複削除
export const createSet = <T extends Object>(list: T[]): T[] => {
  const isMultidimensionalArray =
    list.length > 0 ? Array.isArray(list[0]) : false;
  return isMultidimensionalArray
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Array.from(new Set((list as any).map(JSON.stringify)), JSON.parse as any) // TODO 修正必要 listの型が間違ってる(?)
    : Array.from(new Set(list));
};
// 特定のフィールドでの重複を削除した配列を返す
export const createListSetOfField = <T extends Object>(
  list: T[],
  field: string
): T[] => {
  // @ts-expect-error TS7053
  const fields = createSet(list.map((el) => el[field]));
  const resList = [];
  for (const f of fields) {
    resList.push(
      // @ts-expect-error TS2345
      list.filter((el) => JSON.stringify(el[field]) === JSON.stringify(f))[0]
    );
  }
  // @ts-expect-error TS2322
  return resList;
};

// urlの最後のdirectory名を取得する
export const getLastDirNameFromHref = (
  pathName: string,
  prevUrlParam: "goods" | "flower-stand"
): string => {
  const params = pathName.split("/");
  const eventIndex = params.indexOf(prevUrlParam);
  if (eventIndex < 0) {
    // @ts-expect-error TS2322
    return null;
  }
  // decodeURIComponentにundefinedやnullを渡すと文字列で返してくるのでチェック入れてる
  const suffixName = params[eventIndex + 1];
  // @ts-expect-error TS2322
  if (!suffixName) return null;
  return decodeURIComponent(suffixName);
};

// TODO 多分 obj: `{ [key: string]: {} }`だけど外側の型もわからんから確認後修正
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addKeyToObjectMap = (obj: { [key: string]: any }) => {
  const resObj = {};
  for (const [key, value] of Object.entries(obj)) {
    Object.assign(resObj, { [key]: { ...value, _id: key } });
  }
  return resObj;
};
// 端末がスマートフォンか
export const isSmartPhone = (): boolean => {
  return !!/(iPhone|iPad|iPod|Android)/i.exec(navigator.userAgent);
};
/**
 * return if device is not pc
 * replace isSmartPhone to this func in the future.
 */
export const isSP = () => {
  const sp = !!/(iPhone|iPad|iPod|Android)/i.exec(navigator.userAgent);
  // if ipad, can't detect by grep iPad
  const agent = window.navigator.userAgent.toLowerCase();
  const ipad =
    agent.indexOf("ipad") > -1 ||
    (agent.indexOf("macintosh") > -1 && "ontouchend" in document);
  return sp || ipad;
};
// 端末がAndroidか
export const isAndroid = (): boolean => {
  return !!/(Android)/i.exec(navigator.userAgent);
};

// Object.valuesの代わりに使う。(because .values is unsupported in many browsers)
export const convertMapToValues = <T extends Object>(mapObj: {
  [key: string]: T;
}): T[] => {
  if (!mapObj || Object.keys(mapObj).length === 0) {
    return [];
  }
  const list = Object.keys(mapObj).map((key) => {
    return mapObj[key];
  });
  // @ts-expect-error TS2322
  return list;
};

// HOTFIX
export const convertMapToValuesWithId = <T extends Object>(mapObj: {
  [key: string]: T;
}): T[] => {
  if (!mapObj || Object.keys(mapObj).length === 0) {
    return [];
  }
  const list = Object.keys(mapObj).map((key) => {
    return { _id: key, ...mapObj[key] };
  });
  // @ts-expect-error TS2322
  return list;
};

// http~ リンクをaタグつけて返す
// @ts-expect-error TS7006
export const AutoLink = (str) => {
  const regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
  // @ts-expect-error TS7006
  const regexp_makeLink = function (all, url, _h, _href) {
    // return '<a href="h' + href + '">' + url + '</a>';
    return "<a>" + url + "<a>";
  };
  const _link = str.replace(regexp_url, regexp_makeLink);
  // @ts-expect-error TS7006
  return _link.split("<a>").map((msg, i) => {
    if (msg.startsWith("http")) {
      const link = msg.replace(" ", "");
      return (
        <a key={i} href={link} target="_blank" rel="noreferrer">
          {msg}
        </a>
      );
    } else {
      return <React.Fragment key={i}>{replaceBr(msg)}</React.Fragment>;
    }
  });
};

export const convertEventsArrayToDict = (events: Event[]) => {
  const rets = {};
  events.forEach((val) => {
    // @ts-expect-error TS2538
    rets[val._id] = val;
  });
  return rets;
};

export const convertPlacesArrayToDict = (
  places: Place[]
): {
  [key: string]: Place;
} => {
  const rets: { [key: string]: Place } = {};
  places.forEach((val) => {
    rets[val._id ?? val.code] = val;
  });
  return rets;
};

export const convertArrayToDict = <T extends Object>(
  array: T[]
): { [key: string]: T } => {
  const rets = {};
  // TODO 型わからん
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  array.forEach((val: any) => {
    // @ts-expect-error TS7053
    rets[val._id] = { ...val, _id: val._id };
  });
  return rets;
};

// @ts-expect-error TS7006
export const addKeyFieldToMap = (mapObj) => {
  const rets = {};
  Object.keys(mapObj).forEach((key) => {
    const obj = mapObj[key];
    // @ts-expect-error TS7053
    rets[key] = { ...obj, _id: key };
  });
  return rets;
};

// @ts-expect-error TS7006
export const isExistChainingMap = (map, ...properties: string[]): boolean => {
  let chainingMap = map;
  for (const property of properties) {
    if (!chainingMap) {
      return false;
    }
    chainingMap = chainingMap[property];
  }
  return true;
};

/**
 * encode uri
 * @ref https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
 * @param str
 */
export const fixedEncodeURIComponent = (str: string) => {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
};

/**
 * return display flag by period
 * @param display
 * @param startAt
 * @param endAt
 */
export const isDisplayPeriod = (
  display: boolean,
  startAt: Timestamp | undefined,
  endAt: Timestamp | undefined
) => {
  if (!display) {
    return false;
  }

  if (!startAt || !endAt) {
    return false;
  }

  const nowTimestamp = getNowTimestamp();
  if (startAt.seconds <= nowTimestamp && nowTimestamp < endAt.seconds) {
    return true;
  } else {
    return false;
  }
};

/**
 * for cache clear
 */
export const getUpdateVersion = (cacheSeconds = 60) => {
  return Math.floor(new Date().getTime() / (cacheSeconds * 1000));
};
export const getUrlWithUpdateVersion = (url: string, cacheSeconds = 60) => {
  return `${url}?t=${getUpdateVersion(cacheSeconds)}`;
};

/**
 * EventInfo utility
 */
export type EventInfo = Event & {
  eventId: string;
  linkEventId: string;
  eventTitle: string;
  thumbnail: string;
  eventOpenDate: string;
  eventOpenDateFull: string;
  eventOpenDay: string;
  eventOpenTime: string;
  eventOpenDatetime: string;
  eventStartTime: string;
  eventEndDate: string;
  eventEndDateFull: string;
  isLongEvent: boolean;
  eventSaleStatus: EventSaleStatus;
  eventSaleStatusImg: string;
  goodsSaleStatus: EventSaleStatus;
  goodsSaleStatusImg: string;
  flowerStandSaleStatus: EventSaleStatus;
  flowerStandSaleStatusImg: string;
  ticketOpenDateInfo: ReturnType<typeof getDateInfo>;
  goodsOpenDate: string;
  goodsOpenDay: string;
  goodsOpenTime: string;
  goodsOpenDateDayTime: string;
  goodsCloseDate: string;
  goodsCloseDay: string;
  goodsCloseTime: string;
  goodsCloseDateDayTime: string;
  isOpenStreamingPage: boolean;
  goodsBannerURL: string;
  flowerStandBannerURL: string;
  isFesEvent: boolean;
};

export const getEventIdFromHref = (
  pathName: string,
  prevUrlParam: "events" | "ticket"
): null | string => {
  if (!pathName) {
    return null;
  }
  const params = pathName.split("/");
  const eventIndex = params.indexOf(prevUrlParam);
  if (eventIndex < 0) {
    return null;
  }
  // @ts-expect-error TS2322
  return params[eventIndex + 1];
};

interface DisplayOption {
  imgResolution?: "480" | "1280"; // 480は極小サイズの時のみ使用する
}
export const getEventDisplayInfo = (
  event: Event,
  option: DisplayOption = { imgResolution: "1280" }
): EventInfo => {
  // firestoreのeventから表示に使用する形で返す
  const { imgResolution } = option;

  const eventId = event._id;
  const eventTitle = replaceBr(event.title);
  const artists = replaceBr(event.artists);
  const PUBLIC_DB_URL = appConfig.publicStorageDomain;
  const { storagePath } = event;
  // image path's suffix is different between web cms and pyCMS
  // HOTFIX:
  // 1MBだと画像が荒くなるので、イベントサムネ以外は、Firebase Extensionで生成された画像を使わない（影響範囲がわからないので）
  // そもそも画像サイズ制限を加えたためFirebase Extensionを使わなくていいかもを検討
  const thumbnail = storagePath?.eventBanner
    ? `${PUBLIC_DB_URL}${storagePath.eventBanner}_1280x720`
    : selectImgPath(event.defaultImg, imgResolution);
  const goodsBannerURL = storagePath?.goodsBanner
    ? PUBLIC_DB_URL + storagePath.goodsBanner
    : null;
  const flowerStandBannerURL = storagePath?.flowerStandBanner
    ? PUBLIC_DB_URL + storagePath.flowerStandBanner
    : null;
  const { placeInfo } = event;
  const linkEventId = eventId;

  const [firstEventPart] = event.parts.slice(0, 1);
  const [lastEventPart] = event.parts.slice(-1);
  // @ts-expect-error TS18048
  const dt = new Date(firstEventPart.openTime.seconds * 1000);
  // mm/dd
  const eventOpenDate =
    toDoubleDigits(dt.getMonth() + 1) + "/" + toDoubleDigits(dt.getDate());
  // yyyy/mm/dd
  const eventOpenDateFull = dt.getFullYear() + "/" + eventOpenDate;
  // 曜日
  const eventOpenDay = dayOfWeekStr(dt.getDay());
  // hh:ii 開演は30分後としている
  const eventOpenTime =
    toDoubleDigits(dt.getHours()) + ":" + toDoubleDigits(dt.getMinutes());
  const startAt = !event.eventStartTime
    ? new Date(dt.getTime() + 1800000)
    : new Date(event.eventStartTime.seconds * 1000);
  // TODO 型はっきりさせて 文字列なのかタイムスタンプなのか
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const eventStartTime: any =
    toDoubleDigits(startAt.getHours()) +
    ":" +
    toDoubleDigits(startAt.getMinutes());
  const eventOpenDatetime =
    String(dt.getFullYear()) +
    "年" +
    ("0" + (dt.getMonth() + 1)).slice(-2) +
    "月" +
    ("0" + dt.getDate()).slice(-2) +
    "日" +
    "(" +
    eventOpenDay +
    ")" +
    eventOpenTime;

  // @ts-expect-error TS18048
  const eventEndDatetime = new Date(lastEventPart.openTime.seconds * 1000);
  const eventEndDate =
    toDoubleDigits(eventEndDatetime.getMonth() + 1) +
    "/" +
    toDoubleDigits(eventEndDatetime.getDate());
  const eventEndDateFull = eventEndDatetime.getFullYear() + "/" + eventEndDate;

  // related streaming
  const isOpenStreamingPage = isOpenEventStreamingPage(event);
  const streamingPageOpenTime = !event.streamingPageOpenTime
    ? null
    : getDateInfo(event.streamingPageOpenTime);
  const streamingPageOpenInfo = streamingPageOpenTime
    ? {
        streamingOpenDate: streamingPageOpenTime.date,
        streamingOpenDay: streamingPageOpenTime.day,
        streamingOpenTime: streamingPageOpenTime.time,
        streamingOpenDateDayTime: streamingPageOpenTime.dateDayTime,
      }
    : null;

  const isLongEvent = eventOpenDate !== eventEndDate;

  // sale status
  const eventSaleStatusInfo = getSaleStatusInfo(
    event.isTicketSellingPageOpen,
    event.ticketSellingOpenTime,
    event.ticketSellingCloseTime
  );
  const goodsSaleStatusInfo = getSaleStatusInfo(
    event.isGoodsSellingPageOpen,
    event.goodsSellingOpenTime,
    event.goodsSellingCloseTime
  );
  const flowerSaleStatusInfo = getSaleStatusInfo(
    event.isFlowerStandOpen,
    event.flowerStandOpenTime,
    event.flowerStandCloseTime
  );
  const saleStatusInfo = {
    eventSaleStatus: eventSaleStatusInfo.saleStatus,
    eventSaleStatusImg: eventSaleStatusInfo.saleStatusImg,
    goodsSaleStatus: goodsSaleStatusInfo.saleStatus,
    goodsSaleStatusImg: goodsSaleStatusInfo.saleStatusImg,
    flowerStandSaleStatus: flowerSaleStatusInfo.saleStatus,
    flowerStandSaleStatusImg: flowerSaleStatusInfo.saleStatusImg,
  };

  // 販売期間
  const ticketOpenDateInfo = !event.ticketSellingOpenTime
    ? null
    : getDateInfo(event.ticketSellingOpenTime);
  const goodsOpenDateInfo = !event.goodsSellingOpenTime
    ? null
    : getDateInfo(event.goodsSellingOpenTime);
  const goodsCloseDateInfo = !event.goodsSellingCloseTime
    ? null
    : getDateInfo(event.goodsSellingCloseTime);
  const goodsDateInfo =
    goodsOpenDateInfo && goodsCloseDateInfo
      ? {
          goodsOpenDate: goodsOpenDateInfo.date,
          goodsOpenDay: goodsOpenDateInfo.day,
          goodsOpenTime: goodsOpenDateInfo.time,
          goodsOpenDateDayTime: goodsOpenDateInfo.dateDayTime,
          goodsCloseDate: goodsCloseDateInfo.date,
          goodsCloseDay: goodsCloseDateInfo.day,
          goodsCloseTime: goodsCloseDateInfo.time,
          goodsCloseDateDayTime: goodsCloseDateInfo.dateDayTime,
        }
      : null;

  return {
    ...event,
    // @ts-expect-error TS2322
    eventId,
    // @ts-expect-error TS2322
    linkEventId,
    eventTitle,
    artists,
    thumbnail,
    placeInfo,
    eventOpenDate,
    eventOpenDateFull,
    eventOpenDay,
    eventOpenTime,
    eventOpenDatetime,
    eventEndDate,
    eventEndDateFull,
    isLongEvent,
    eventStartTime,
    isOpenStreamingPage,
    // @ts-expect-error TS2322
    ticketOpenDateInfo,
    // @ts-expect-error TS2322
    goodsBannerURL,
    // @ts-expect-error TS2322
    flowerStandBannerURL,
    ...streamingPageOpenInfo,
    ...saleStatusInfo,
    ...goodsDateInfo,
    isFesEvent: event.dayRefs && event.dayRefs.length > 0,
  };
};
const getDateInfo = (datetime: Timestamp) => {
  const dt = new Date(datetime.seconds * 1000);
  // mm/dd
  const date =
    toDoubleDigits(dt.getMonth() + 1) + "/" + toDoubleDigits(dt.getDate());
  // yyyy/mm/dd
  const dateFull = dt.getFullYear() + "/" + date;
  // 曜日
  const day = dayOfWeekStr(dt.getDay());
  // hh:ii 開演は30分後としている
  const time =
    toDoubleDigits(dt.getHours()) + ":" + toDoubleDigits(dt.getMinutes());
  // mm/dd(曜日)hh:ii
  const dateDayTime = `${date}(${day})${time}`;
  // yyyy/mm/dd(曜日)hh:ii
  const dateFullDayTime = `${dateFull}(${day})${time}`;
  return { date, dateFull, day, time, dateDayTime, dateFullDayTime };
};
export const getSaleStatusInfo = (
  isOpen: boolean,
  openTime: Timestamp | undefined,
  closeTime: Timestamp | undefined
) => {
  let saleStatus: EventSaleStatus = "NONE";
  let saleStatusImg = null;
  // if isOpen is false or openTime,closeTime is undefined, status is 'NONE'
  if (!isOpen || !openTime || !closeTime) {
    return { saleStatus, saleStatusImg };
  }
  const nowTimestamp = getNowTimestamp();
  if (nowTimestamp < openTime.seconds) {
    saleStatus = "BEFORE_SALE";
    saleStatusImg = label_soon;
  } else if (
    openTime.seconds <= nowTimestamp &&
    nowTimestamp < closeTime.seconds
  ) {
    saleStatus = "ON_SALE";
    saleStatusImg = label_onsale;
  } else if (closeTime.seconds <= nowTimestamp) {
    saleStatus = "END";
    saleStatusImg = label_end;
  }

  return { saleStatus, saleStatusImg };
};

export const getEventById = (events: Event[], eventId: string) => {
  if (events.length === 0 || !eventId) {
    return null;
  }
  const filterEvent = events.filter((event) => {
    return event._id === eventId;
  });
  if (filterEvent.length === 0) {
    return null;
  }
  return filterEvent[0];
};
// 開催当日のeventを返す（現状１つ 当日の0時からイベント開始後の2時間まで
export const getTodayEvent = (events: Event[]): Event => {
  // const nowTimestamp = getNowTimestamp();
  const event = events.filter((el) => isTodayEvent(el));
  // @ts-expect-error TS2322
  return event.length !== 0 ? event[0] : null;
};
/**
 * return if event is today's
 * @param event
 */
export const isTodayEvent = (event: Event) => {
  const nowTimestamp = getNowTimestamp();
  const _d = new Date(event.datetime.seconds * 1000);
  const d = new Date(_d.getFullYear(), _d.getMonth(), _d.getDate(), 0, 0, 0);
  const eventDateTimestamp = getNowTimestamp(d);
  return (
    !event.isClosed &&
    eventDateTimestamp < nowTimestamp &&
    nowTimestamp < event.datetime.seconds + EVENT_OPEN_TIME_PERIOD
  );
};

// @ts-expect-error TS7006
export const parseQuery = (query) => {
  if (query === undefined || query === "") {
    return null;
  }
  // ?を外す
  const queryStr = query.slice(1);
  if (queryStr === "") {
    return null;
  }

  // decodeしてparseする
  const decodedQueryStr = decodeURI(queryStr);
  const parsedQuery = parse(decodedQueryStr);

  return parsedQuery;
};

/**
 * admin
 */
export const getQueryObject = (location: Location) => {
  const query = location.search;
  if (!query) {
    return null;
  }
  const decode = decodeURI(query);
  const queryObject = parse(decode.slice(1));
  return queryObject;
};

/**
 * streaming
 */

export const set_cookie = (cookie: Cookie, streaming_domain: string) => {
  // console.log("Selected cookie", this._cookies[vid], streaming_domain)
  Object.keys(cookie.cookie).forEach((key, _idx) => {
    // const [cookies, setCookie] = useCookies([key]);
    // cookies.set(key, cookie[key], {domain: streaming_domain, path: "/"})
    // @ts-expect-error TS7053
    document.cookie = `${key}=${cookie.cookie[key]};domain=${streaming_domain};path=/`;
  });
};

export const domain_from_url = (url: string) => {
  const match = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?=]+)/im.exec(
    url
  );
  if (match) {
    const [, result] = match;
    // @ts-expect-error TS2345
    const match_ = /^[^.]+\.(.+\..+)$/.exec(result);
    if (match_) {
      return match_[1];
    }
    return result;
  }
  return undefined;
};

export const getCookieValue = (name: string) => {
  const ret = new RegExp("(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)").exec(
    document.cookie
  );
  return ret ? ret.pop() : "";
};

export const getLocalTimeDiff = async () => {
  const begin = new Date();
  const ret = await fetch(window.location.href, {
    method: "HEAD",
    cache: "no-store",
  });
  const end = new Date();
  // @ts-expect-error TS2769
  const resp = new Date(ret.headers.get("Date"));
  return (resp.getTime() - (end.getTime() + begin.getTime()) / 2) / 1000;
};
