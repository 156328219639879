import firebase from "firebase/app";
import "firebase/auth";

const URI = process.env.REACT_APP_BACKEND_API_URL;

export type PortalTicketBonusMedia = {
  // アップロードされたファイルのID
  mediaId: string;
  // アップロードされたファイルの名前
  filename: string;
  // 特典名
  bonusName: string;
  // ダウンロード開始日時
  releaseAt?: Date;
  // ダウンロード期限
  expiredAt?: Date;
  // ダウンロードURL
  urlForDownload?: string;
};

type Auth = firebase.auth.Auth;
// FIXME: main/packages/grpcを参考に型ファイルだけでも共有したい
type FetchConnectClient = {
  updateUser: (req: { name: string; email: string }) => Promise<{
    userId: string;
    userFirestoreId: string;
    name: string;
    email: string;
  }>;
  getTicketBonusMedia: (req: { eventId: string; ticketId: string }) => Promise<{
    ticketBonusMedia?: PortalTicketBonusMedia[];
  }>;
};

export const createConnectClient: (
  auth: Auth | undefined
) => FetchConnectClient = (auth) => {
  const setHeader = async () => {
    const baseHeader = {
      "Content-Type": "application/json",
      "connect-protocol-version": "1",
    } as const;
    if (auth === undefined) return baseHeader;
    const token = await auth.currentUser?.getIdToken();
    return {
      ...baseHeader,
      Authorization: "Bearer " + token,
    };
  };

  return {
    updateUser: async (req) => {
      const headers = await setHeader();

      const response = await fetch(
        URI + "/spwn.account.v1.PortalAccountService/UpdateUser",
        {
          method: "POST",
          headers: new Headers(headers),
          body: JSON.stringify(req),
        }
      );
      return response.json();
    },
    getTicketBonusMedia: async (req) => {
      const headers = await setHeader();
      const query = new URLSearchParams({
        connect: "v1",
        encoding: "json",
        message: JSON.stringify(req),
      });

      const response = await fetch(
        URI + "/spwn.event.v1.PortalEventService/GetTicketBonusMedia?" + query,
        {
          method: "GET",
          headers: new Headers(headers),
        }
      );
      const res = (await response.json()) as {
        ticketBonusMedia?: (Omit<
          PortalTicketBonusMedia,
          "releaseAt" | "expiredAt"
        > & { releaseAt?: string; expiredAt?: string })[];
      };
      if (!res.ticketBonusMedia) return res as {};

      return {
        ticketBonusMedia: res.ticketBonusMedia.map<PortalTicketBonusMedia>(
          (bonus) => {
            const clone = bonus as PortalTicketBonusMedia;
            if (clone.releaseAt)
              clone.releaseAt = new Date(Number(clone.releaseAt));
            if (clone.expiredAt)
              clone.expiredAt = new Date(Number(clone.expiredAt));
            return clone;
          }
        ),
      };
    },
  };
};
