import { Code, ConnectError } from "@bufbuild/connect";
import { MyEventTicketData } from "@spwn/types/firebase/firestore";
import firebase from "firebase/app";
import { useMemo } from "react";
import useSWR from "swr";
import {
  createConnectClient,
  PortalTicketBonusMedia,
} from "utility/connectWeb";

export type MyTicketBonusMedia = PortalTicketBonusMedia & {
  eventId: string;
  ticketId: string;
};

export const useMyTicketBonusMedia = (myTickets: MyEventTicketData[]) => {
  const auth = firebase.auth();
  const connectClient = createConnectClient(auth);

  const args = useMemo(
    () =>
      myTickets.flatMap((product) =>
        // NOTE: product.goodsも対応するかは要確認
        product.tickets
          .map<[string | undefined, string | undefined]>((ticket) => [
            product.event._id,
            ticket._id,
          ])
          .filter((arg): arg is [string, string] => !!arg[0] && !!arg[1])
      ),
    [myTickets]
  );

  return useSWR(
    ["getTicketBonusMedia", args],
    async ([_, ids]) => {
      const result = await Promise.all(
        ids.map(async ([eventId, ticketId]) => {
          try {
            const response = await connectClient.getTicketBonusMedia({
              eventId,
              ticketId,
            });
            return (
              response.ticketBonusMedia?.map<MyTicketBonusMedia>((bonus) => ({
                ...bonus,
                eventId,
                ticketId,
              })) ?? []
            );
          } catch (e) {
            if (e instanceof ConnectError && e.code === Code.NotFound) {
              // 共有チケットだとエラーになるはずなのでここで無視する
              // ※現状エラーにはなっていない
              return [];
            }
            throw e;
          }
        })
      );
      return result.flat();
    },
    {
      revalidateOnFocus: false,
    }
  );
};
